import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCog,
  faChartBar,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";
function Sidebar() {
  return (
    <div className="zhigh flex flex-col items-center p-4 space-y-4 bg-gray-800 text-white h-full justify-between ">
      <div className="space-y-4">
        <IconItem icon={faUser} tooltip="Profile" />
        <IconItem icon={faChartBar} tooltip="Analytics" />
        <IconItem icon={faCommentDots} tooltip="Chat" />
      </div>
      <div className="space-y-4">
        <IconItem icon={faCog} tooltip="Settings" />
      </div>
    </div>
  );
}

function IconItem({ icon, tooltip }) {
  return (
    <div className="relative group">
      <FontAwesomeIcon icon={icon} size="2x" className="cursor-pointer" />
      <div className=" text-lg absolute left-0 ml-12 opacity-0 top-1/2 transform -translate-y-1/2 group-hover:opacity-100 transition-opacity duration-500 ease-in-out bg-gray-700 px-2 py-1 rounded">
        {tooltip}
      </div>
    </div>
  );
}

export default Sidebar;
