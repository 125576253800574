import React, { useState, useRef, useEffect } from "react";

function ChatInterface({ toggleChartVisibility }) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const messagesEndRef = useRef(null);

  const handleSend = () => {
    if (input.trim()) {
      // Add the user's message to the messages array
      setMessages([...messages, { sender: "user", text: input.trim() }]);

      // Simulate an AI response
      setTimeout(() => {
        setMessages([
          ...messages,
          { sender: "user", text: input.trim() },
          {
            sender: "ai",
            text: "Sounds good mate, here's your new chart.",
            action: "toggle_chart",
          },
        ]);
      }, 1000);

      // Clear the input
      setInput("");
    }
  };

  useEffect(() => {
    // Automatically scroll to the bottom of chat box when new messages are added
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="p-4 bg-white border-t border-gray-300 h-96 overflow-y-scroll w-96">
      {messages.map((message, index) => (
        <div
          key={index}
          className={`my-2 ${
            message.sender === "user" ? "text-right" : "text-left"
          }`}
        >
          <div
            className={`inline-block px-4 py-3 rounded-lg ${
              message.sender === "user"
                ? "bg-blue-500 text-white"
                : "bg-gray-300"
            }`}
          >
            {message.text}
            {message.sender === "ai" && message.action === "toggle_chart" && (
              <button
                className={"bg-slate-50 p-3 mt-3 rounded-lg "}
                onClick={toggleChartVisibility}
              >
                Add to Dashboard - Main
              </button>
            )}
          </div>
        </div>
      ))}
      <div ref={messagesEndRef}></div>
      <div className="absolute bottom-2 left-2 right-2 flex">
        <input
          className="flex-1 p-2 border rounded-l-md"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => (e.key === "Enter" ? handleSend() : null)}
          placeholder="Type your message..."
        />
        <button
          onClick={handleSend}
          className="bg-blue-500 text-white p-2 rounded-r-md"
        >
          Send
        </button>
      </div>
    </div>
  );
}

export default ChatInterface;
