import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faCompress,
  faExpand,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";

export function DataCard({
  title,
  children,
  minimized,
  setMinimized,
  moveCard,
}) {
  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const tooltipRef = useRef(null); // Ref for the tooltip div

  // Add an event listener to the document to check for outside clicks
  useEffect(() => {
    function handleOutsideClick(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setTooltipVisible(false);
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div
      className={`relative group border border-gray-200 bg-white rounded-lg shadow-lg transform transition-all duration-300 hover:shadow-xl hover:-translate-y-1 ${
        minimized ? "htoggle" : "h-auto"
      }`}
    >
      <div
        className={`transition-all duration-300 flex items-center justify-between bg-indigo-100 px-4 py-3 ${
          minimized ? "rounded group-hover:rounded" : "rounded-t-lg"
        } group-hover:bg-indigo-500`}
      >
        <h3 className="text-xl text-gray-600 group-hover:text-white">
          {title}
        </h3>
        <div className="flex items-center relative opacity-10 group-hover:opacity-100">
          <FontAwesomeIcon
            icon={faArrowUp}
            className="text-gray-900 group-hover:text-white cursor-pointer mx-2"
            onClick={() => moveCard(title, -1)} // move up
          />
          <FontAwesomeIcon
            icon={faArrowDown}
            className="text-gray-900 group-hover:text-white cursor-pointer mx-2"
            onClick={() => moveCard(title, 1)} // move down
          />
          <FontAwesomeIcon
            icon={minimized ? faExpand : faCompress}
            className="text-gray-900 group-hover:text-white cursor-pointer mx-2"
            onClick={() => setMinimized(title)}
          />
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="text-gray-900 group-hover:text-white cursor-pointer mx-2"
            onClick={() => setTooltipVisible(!tooltipVisible)}
          />
          {tooltipVisible && (
            <div
              ref={tooltipRef}
              className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg p-2 text-sm z-10"
            >
              This card displays data about XYZ. More details can be found in
              the documentation.
            </div>
          )}
        </div>
      </div>
      {!minimized && <div className="p-4">{children}</div>}
    </div>
  );
}
