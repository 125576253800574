import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
  PieChart,
  Pie,
  AreaChart,
  Area,
  ComposedChart,
  Legend,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";

const getRandom = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

// 1. Temperature Over Time
export function TemperatureChart() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const updateData = () => {
      setData((prevData) => {
        const newDataPoint = {
          time: new Date().toLocaleTimeString(),
          temperature: getRandom(20, 30),
        };
        return prevData.length >= 10
          ? [...prevData.slice(1), newDataPoint]
          : [...prevData, newDataPoint];
      });
    };
    updateData();
    const interval = setInterval(updateData, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart width={400} height={250} data={data}>
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="temperature" stroke="#ff7300" />
      </LineChart>
    </ResponsiveContainer>
  );
}

// 2. Component Power Usage
export function PowerUsageChart() {
  const [data, setData] = useState([
    { name: "CPU", power: 4000 },
    { name: "GPU", power: 2500 },
    { name: "RAM", power: 800 },
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setData([
        { name: "CPU", power: getRandom(3500, 4500) },
        { name: "GPU", power: getRandom(2000, 3000) },
        { name: "RAM", power: getRandom(700, 900) },
      ]);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart width={400} height={250} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="power" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
}

// 3. Overall Power Consumption
export function PowerPieChart() {
  const [data, setData] = useState([
    { name: "CPU", value: 4000 },
    { name: "GPU", value: 2500 },
    { name: "RAM", value: 800 },
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setData([
        { name: "CPU", value: getRandom(3500, 4500) },
        { name: "GPU", value: getRandom(2000, 3000) },
        { name: "RAM", value: getRandom(700, 900) },
      ]);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart width={400} height={250}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={data}
          outerRadius={80}
          fill="#8884d8"
          label
        />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}

// 4. System Uptime
export function UptimeAreaChart() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const updateData = () => {
      setData((prevData) => {
        const newDataPoint = {
          time: new Date().toLocaleTimeString(),
          uptime: getRandom(95, 100),
        };
        return prevData.length >= 10
          ? [...prevData.slice(1), newDataPoint]
          : [...prevData, newDataPoint];
      });
    };
    updateData();
    const interval = setInterval(updateData, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart width={400} height={250} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="uptime"
          stroke="#8884d8"
          fill="#8884d8"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

// 5. Network Traffic
export function NetworkTrafficChart() {
  const [data, setData] = useState([
    { time: new Date().toLocaleTimeString(), download: 1200, upload: 800 },
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setData((prevData) => {
        const newData = [
          ...prevData,
          {
            time: new Date().toLocaleTimeString(),
            download: getRandom(1000, 1500),
            upload: getRandom(600, 1000),
          },
        ];
        return newData.length > 10
          ? newData.slice(newData.length - 10)
          : newData;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart width={400} height={250} data={data}>
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="download" barSize={20} fill="#413ea0" />
        <Line type="monotone" dataKey="upload" stroke="#ff7300" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

// 6. Memory Usage
export function MemoryRadarChart() {
  const [data, setData] = useState([
    { subject: "Cache", A: 120, fullMark: 150 },
    { subject: "RAM", A: 80, fullMark: 100 },
    { subject: "VRAM", A: 90, fullMark: 100 },
    { subject: "Swap", A: 70, fullMark: 100 },
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setData([
        { subject: "Cache", A: getRandom(100, 150), fullMark: 150 },
        { subject: "RAM", A: getRandom(70, 100), fullMark: 100 },
        { subject: "VRAM", A: getRandom(80, 100), fullMark: 100 },
        { subject: "Swap", A: getRandom(60, 90), fullMark: 100 },
      ]);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <RadarChart cx="50%" cy="50%" outerRadius="90%" data={data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="subject" />
        <PolarRadiusAxis />
        <Radar
          name="Memory"
          dataKey="A"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.6}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
}

export function VisibilityToggledChart() {
  const [data, setData] = useState([
    {
      name: "Jan",
      uv: getRandom(2000, 5000),
      pv: getRandom(1000, 3000),
      amt: getRandom(1000, 3000),
    },
    {
      name: "Feb",
      uv: getRandom(2000, 5000),
      pv: getRandom(1000, 3000),
      amt: getRandom(1000, 3000),
    },
    // Initialize some more months if needed...
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setData((prevData) =>
        prevData.map((item) => ({
          ...item,
          uv: getRandom(2000, 5000),
          pv: getRandom(1000, 3000),
          amt: getRandom(1000, 3000),
        }))
      );
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="pv" stroke="#8884d8" />
        <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
      </LineChart>
    </ResponsiveContainer>
  );
}
