import React, { useState } from "react";
import Sidebar from "./SidebarMenu.js";
import { DataCard } from "./DataCard.js";
import {
  TemperatureChart,
  PowerUsageChart,
  PowerPieChart,
  UptimeAreaChart,
  NetworkTrafficChart,
  MemoryRadarChart,
  VisibilityToggledChart,
} from "./YourChartsFile";
import ChatButton from "./ChatButton.js";
import ChatInterface from "./ChatInterface.js";

export default function Dashboard() {
  const [showVisibilityToggledChart, setShowVisibilityToggledChart] =
    useState(false);

  // Additional states to manage card order and minimized cards.
  const initialOrder = [
    "Temperature",
    "Power Usage",
    "Uptime",
    "Network Traffic",
    "Memory Usage",
    "Power Distribution",
    "AI Generated Graph",
  ];
  const [order, setOrder] = useState(initialOrder);
  const [minimizedCards, setMinimizedCards] = useState([]);

  const toggleChartVisibility = () => {
    setShowVisibilityToggledChart((prevState) => !prevState);
    console.log("Toggle Chart Visibility Called!");
  };

  const setMinimized = (title) => {
    if (minimizedCards.includes(title)) {
      setMinimizedCards(
        minimizedCards.filter((cardTitle) => cardTitle !== title)
      );
    } else {
      setMinimizedCards([...minimizedCards, title]);
    }
  };

  const moveCard = (title, direction) => {
    const index = order.indexOf(title);
    if (
      (direction === -1 && index === 0) ||
      (direction === 1 && index === order.length - 1)
    ) {
      return; // Can't move beyond the array limits
    }

    const newOrder = [...order];
    const temp = newOrder[index];
    newOrder[index] = newOrder[index + direction];
    newOrder[index + direction] = temp;

    setOrder(newOrder);
  };

  const renderCard = (title) => {
    switch (title) {
      case "Temperature":
        return <TemperatureChart />;
      case "Power Usage":
        return <PowerUsageChart />;
      case "Uptime":
        return <UptimeAreaChart />;
      case "Network Traffic":
        return <NetworkTrafficChart />;
      case "Memory Usage":
        return <MemoryRadarChart />;
      case "Power Distribution":
        return <PowerPieChart />;
      case "AI Generated Graph":
        return <VisibilityToggledChart />;
      default:
        return null;
    }
  };

  return (
    <div className="flex h-screen bg-gray-300">
      <Sidebar />
      <div className="sticky top-0 bg-gray-800"></div>
      <div className="flex-1 overflow-y-auto p-6">
        <div className="flex  justify-between items-center mb-6">
          <h1 className="text-left text-3xl leading-9 tracking-tight text-gray-900">
            <span className="font-bold">SNEED</span> AI
          </h1>
          <div className="text-right text-gray-600">
            <div>&copy; 2023 SNEEDLABS. All rights reserved.</div>
            <div>Version 0.1.4</div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-6">
          {order
            .filter(
              (title) =>
                title !== "AI Generated Graph" || showVisibilityToggledChart
            )
            .map((title) => (
              <DataCard
                key={title}
                title={title}
                minimized={minimizedCards.includes(title)}
                setMinimized={setMinimized}
                moveCard={moveCard}
              >
                {renderCard(title)}
              </DataCard>
            ))}
        </div>
      </div>
      <ChatButton toggleChartVisibility={toggleChartVisibility} />
    </div>
  );
}
