import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import ChatInterface from "./ChatInterface.js";

function ChatButton({ toggleChartVisibility }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed bottom-0 right-0 mr-5 w-96 shadow-lg">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-96 flex items-center p-4 bg-indigo-500 text-white rounded-tl-lg rounded-tr-lg shadow hover:bg-blue-600"
      >
        <FontAwesomeIcon icon={faCommentDots} className="mr-2" />
        Chat With AI
      </button>
      {isOpen && (
        <ChatInterface toggleChartVisibility={toggleChartVisibility} />
      )}
    </div>
  );
}

export default ChatButton;
